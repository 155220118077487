import { Box, Card, Typography } from '@oresundsbron/bridge-ui';
import { ProductCardGroup as ProductCardGroupType } from '@oresundsbron/api';
import { FC, useMemo } from 'react';
import { pipe } from 'fp-ts/lib/function';
import { fromNullable, map, toUndefined } from 'fp-ts/lib/Option';
import { map as mapA } from 'fp-ts/lib/Array';
import { RichText } from '..';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';

export const ProductCardGroup: FC<ProductCardGroupType> = ({
  title,
  tagline,
  cards,
}) => {
  const { t } = useTranslation(['common']);

  const content = useMemo(
    () =>
      pipe(
        cards,
        fromNullable,
        map((c) => c.items),
        map(
          mapA(({ sys, content, promoted }) => (
            <Card.Root
              key={sys.id}
              shadow={false}
              className={cx(
                promoted
                  ? 'border-[4px] border-primary-950'
                  : 'border border-neutral-100',
                'shadow-xl',
                'col-span-1'
              )}
            >
              {promoted ? (
                <Card.Header className="bg-primary-950 !pb-2 !pt-3">
                  <Typography color="white" intent="label">
                    {t('productCard.popular')}
                  </Typography>
                </Card.Header>
              ) : null}
              <Card.Content>
                {content ? <RichText content={content} /> : null}
              </Card.Content>
            </Card.Root>
          ))
        ),
        toUndefined
      ),
    [cards, t]
  );

  return (
    <Box>
      <Typography
        as="h2"
        intent={tagline ? 'body' : 'headline'}
        size="sm"
        className="text-center"
      >
        {title}
      </Typography>
      {tagline ? (
        <Typography intent="headline" className="mt-4 text-center" size="sm">
          {tagline}
        </Typography>
      ) : null}
      <Box className="mt-12 grid grid-cols-1 gap-6 sm:gap-5 md:grid-cols-3 md:gap-6">
        {content}
      </Box>
    </Box>
  );
};
