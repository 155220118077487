/* eslint-disable @next/next/no-sync-scripts */
import { useEffect, useMemo, useState } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import {
  QueryClientProvider,
  Hydrate,
  DehydratedState,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@oresundsbron/design-system';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { appWithTranslation } from 'next-i18next';
import nProgress from 'nprogress';

import { theme } from '../lib/theme';
import { createQueryClient } from '../lib/query-client';
import { createEmotionCache } from '../lib/emotion';
import { OSBNavBar } from '../modules/NavBar';
import { NextPage } from 'next';
import { PreviewBar } from '../components/PreviewBar';
import { ApiProvider } from '@oresundsbron/api';
import { api, contentful } from '../lib/api/client';
import { setLocale } from '../stores/locale';

import '../styles/globals.css';
import { useRouter } from 'next/router';
import { Loading } from '@oresundsbron/bridge-ui';
import AppInsight from '../components/AppInsight';
import { BridgeStatusBanner } from '../modules/TrafficInformation/BridgeStatusBanner';
import Footer from '../modules/Footer';
import * as gtag from '../lib/gtag';
import Head from 'next/head';
import { Poppins } from 'next/font/google';
import { InformationBanner } from '../modules/InformationBanner';
import Script from 'next/script';
import { FlagsProvider } from '~/components/Flags';
import { Box } from '@oresundsbron/bridge-ui';
import { UserProvider } from '@auth0/nextjs-auth0/client';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

nProgress.configure({ showSpinner: false });

declare global {
  interface Window {
    CookieInformation: {
      loadConsent: () => void;
    };
  }
}

type GetLayoutFn<P> = (
  page: React.ReactElement,
  pageProps: P
) => React.ReactNode;

export type NextPageWithLayout<
  P = { dehydratedState: DehydratedState },
  IP = P
> = NextPage<P, IP> & {
  getLayout?: GetLayoutFn<P>;
};

type AppPropsWithLayout = AppProps<{
  dehydratedState: DehydratedState;
  preview?: boolean;
  locale?: string;
  flags?: Record<string, unknown>;
}> & {
  Component: NextPageWithLayout;
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const shouldShowCookieConsent =
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'development';
function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout & { emotionCache: EmotionCache }) {
  const { isFallback, events, locale, asPath } = useRouter();
  useMemo(() => setLocale(pageProps.locale || ''), [pageProps.locale]);

  const getLayout = useMemo(
    () =>
      Component.getLayout ||
      (((page) => page) as GetLayoutFn<{ dehydratedState: DehydratedState }>),
    [Component.getLayout]
  );
  const queryClient = useMemo(() => createQueryClient(), []);

  useEffect(() => {
    events.on('routeChangeStart', () => nProgress.start());
    events.on('routeChangeComplete', () => nProgress.done());
    events.on('routeChangeError', () => nProgress.done());
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    return () => {
      events.off('routeChangeStart', () => nProgress.start());
      events.off('routeChangeComplete', () => nProgress.done());
      events.off('routeChangeError', () => nProgress.done());
    };
  }, [events]);

  useEffect(() => {
    gtag.pageView(asPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageView(url);
      if (shouldShowCookieConsent) {
        window.CookieInformation.loadConsent(); // https://support.cookieinformation.com/en/articles/5432277-single-page-applications-spa
      }
    };
    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  if (isFallback) {
    return (
      <Loading className="flex h-screen w-full items-center justify-center text-4xl text-primary-600" />
    );
  }

  return (
    <AppInsight>
      <Head>
        <title>Øresundsbron</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link
          href="https://www.googletagmanager.com"
          rel="preconnect"
          crossOrigin="anonymous"
        />
      </Head>
      {shouldShowCookieConsent && (
        <Script
          id="CookieConsent"
          src="https://policy.app.cookieinformation.com/uc.js"
          data-culture={locale?.toUpperCase()}
          data-gcm-version="2.0"
        />
      )}

      {process.env.GTM_ID && (
        <>
          <Script id="google-data-layer">
            {'window.dataLayer = window.dataLayer || [];'}
          </Script>

          <Script id="google-tag-manager">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GTM_ID}');`}
          </Script>
        </>
      )}

      <Script
        strategy="lazyOnload"
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
      />
      <UserProvider>
        <ApiProvider contentful={contentful} api={api}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ThemeProvider context={{ theme, prefix: 'osb' }}>
                <CacheProvider value={emotionCache}>
                  <style jsx global>{`
                    html,
                    body {
                      font-family: ${poppins.style.fontFamily};
                      min-height: 100vh;
                    }
                  `}</style>
                  <FlagsProvider flags={pageProps.flags || {}}>
                    <BridgeStatusBanner />
                    <InformationBanner />
                    <OSBNavBar />
                    {pageProps.preview ? <PreviewBar /> : null}
                    {/* The Box wrapped around getLayout is used to set a min-height to prevent the footer from causing a unnecessary Cumulative Layout Shift*/}
                    <Box className="min-h-page">
                      {getLayout(<Component {...pageProps} />, pageProps)}
                    </Box>
                    <Footer />
                  </FlagsProvider>
                </CacheProvider>
              </ThemeProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientProvider>
        </ApiProvider>
      </UserProvider>
    </AppInsight>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  gtag.webMetric(metric);
}

const TranslatedApp: ReturnType<typeof appWithTranslation> = appWithTranslation(
  App as React.ComponentType<any>
);

export default TranslatedApp;
