import { useRouter } from 'next/router';
import { formatFileSize } from '@oresundsbron/utilities';
import { Asset } from '@oresundsbron/validators';
import { File } from '@oresundsbron/icons';

interface AssetFileProps extends Asset {}

export const AssetFile = ({
  url,
  description,
  title,
  size,
}: AssetFileProps) => {
  const { locale } = useRouter();

  return (
    <a
      href={url}
      target="_blank"
      className="group mb-4 flex items-center rounded-lg bg-neutral-50 p-6 hover:bg-primary-50"
    >
      <div className="relative mr-4">
        <div className="absolute inset-0 top-2 grid place-items-center text-2xs font-semibold uppercase tracking-tighter">
          <span>{url.split('.').pop()}</span>
        </div>
        <File className="h-12 w-12 text-primary-900" />
      </div>
      <span>
        <span className="block font-semibold group-hover:underline">
          {title}
        </span>

        {description && (
          <span className="block text-xs leading-tight">{description}</span>
        )}
        {size && (
          <span className="block text-xs leading-tight">
            {formatFileSize(size, locale)}
          </span>
        )}
      </span>
    </a>
  );
};
