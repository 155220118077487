import { Close, Hamburger } from '@oresundsbron/icons';
import { useNavigation } from '../hooks/useNavigation';
import { useMemo } from 'react';
import { pipe } from 'fp-ts/lib/function';
import { filter, map } from 'fp-ts/lib/Array';
import { isNavigationSection } from '../lib/navigation';
import { useLinks } from '../hooks/useLinks';
import Link from 'next/link';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useTranslation } from 'next-i18next';

export const MainNavigation = () => {
  const data = useNavigation('Main');
  const { getLink } = useLinks();
  const { t } = useTranslation(['common']);
  const sections = useMemo(
    () =>
      pipe(
        data?.sections || [],
        filter(isNavigationSection),
        map(({ sys, title, links }) => (
          <li key={sys.id}>
            <div className="mb-3 font-semibold uppercase tracking-wider">
              {title}
            </div>
            <ul className="flex flex-col">
              {links.map(({ sys, pageLink, title }) => (
                <li key={sys.id}>
                  <NavigationMenu.Link asChild>
                    <Link
                      href={getLink(pageLink?.sys?.id)?.path || ''}
                      className="inline-block border-l px-4 py-2 text-neutral-600 hover:border-l-2 hover:border-primary-600 hover:pl-[15px] hover:text-primary-800"
                    >
                      {title}
                    </Link>
                  </NavigationMenu.Link>
                </li>
              ))}
            </ul>
          </li>
        ))
      ),
    [data, getLink]
  );

  return (
    <>
      <NavigationMenu.Trigger
        asChild
        onPointerEnter={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
        onPointerMove={(event) => event.preventDefault()}
      >
        <button className="group flex items-center rounded-full border-2 border-transparent p-3 font-medium text-type-secondary hover:bg-neutral-50 hover:text-type-heading lg:px-6 lg:py-2">
          <Hamburger className="hidden group-data-[state=closed]:block" />
          <Close className="hidden group-data-[state=open]:block" />
          <span className="ml-2 hidden lg:inline-block">
            {t('framework.menuLabel')}
          </span>
        </button>
      </NavigationMenu.Trigger>
      <NavigationMenu.Content
        onPointerEnter={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
        onPointerMove={(event) => event.preventDefault()}
        className="absolute left-0 right-0 z-30 mt-5 bg-white shadow-main-nav sm:left-0 sm:right-0 sm:top-full sm:mt-0 sm:w-auto sm:rounded-b-xl sm:border-x sm:border-b sm:border-neutral-100 lg:left-16 lg:right-16"
      >
        <ul className="col-span-full grid grid-cols-1 gap-6 px-4 py-8 sm:grid-cols-2 sm:px-16 md:grid-cols-3">
          {sections}
        </ul>
      </NavigationMenu.Content>
    </>
  );
};
