import { useBridgeStatus } from '../../hooks/useBridgeStatus';
import { useLinks } from '../../hooks/useLinks';
import { Box } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { pipe } from 'fp-ts/lib/function';
import {
  alt,
  fromPredicate,
  getOrElse,
  map,
  match,
  toUndefined,
  chain,
} from 'fp-ts/lib/Option';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { isClosed, isOpen, isWarning } from './utils';
import Link from 'next/link';
import { Ban, CheckCircle, Info, WarningTriangle } from '@oresundsbron/icons';

export const BridgeStatusBanner = () => {
  const { data, isStatusLoading } = useBridgeStatus();
  const { findLinkByCodeRef, findLink } = useLinks();
  const { t } = useTranslation(['bridgeStatus', 'common'], {
    nsMode: 'fallback',
  });
  const { asPath } = useRouter();

  const bgColor = useMemo(
    () =>
      pipe(
        data.status,
        isOpen(() => 'bg-green-50 border-green-100'),
        alt(() =>
          pipe(
            data.status,
            isWarning(() => 'bg-yellow-50 border-yellow-100')
          )
        ),
        alt(() =>
          pipe(
            data.status,
            isClosed(() => 'bg-red-50 border-red-100')
          )
        ),
        getOrElse(() => 'bg-neutral-50 border-neutral-100')
      ),
    [data]
  );
  const icon = useMemo(
    () =>
      pipe(
        data.status,
        isOpen(() => <CheckCircle className="h-4 w-4 sm:h-6 sm:w-6" />),
        alt(() =>
          pipe(
            data.status,
            isWarning(() => (
              <WarningTriangle className="h-4 w-4 sm:h-6 sm:w-6" />
            ))
          )
        ),
        alt(() =>
          pipe(
            data.status,
            isClosed(() => <Ban className="h-4 w-4 sm:h-6 sm:w-6" />)
          )
        ),
        getOrElse(() => <Info className="h-4 w-4 sm:h-6 sm:w-6" />)
      ),
    [data]
  );
  const text = useMemo(
    () =>
      pipe(
        data.status,
        isOpen(() => t('bridgeStatus.open')),
        alt(() =>
          pipe(
            data.status,
            isWarning(() => t('bridgeStatus.warning'))
          )
        ),
        alt(() =>
          pipe(
            data.status,
            isClosed(() => t('bridgeStatus.closed'))
          )
        ),
        getOrElse(() => t('bridgeStatus.unknown'))
      ),
    [data.status, t]
  );

  const buttonColor = useMemo(
    () =>
      pipe(
        data.status,
        isOpen(() => 'bg-green-200 text-green-800 hover:bg-green-300'),
        alt(() =>
          pipe(
            data.status,
            isWarning(() => 'bg-yellow-200 text-yellow-800 hover:bg-yellow-300')
          )
        ),
        alt(() =>
          pipe(
            data.status,
            isClosed(() => 'bg-red-200 text-red-800 hover:bg-red-300')
          )
        ),
        getOrElse(() => 'bg-neutral-200 text-neutral-800 hover:bg-neutral-300')
      ),
    [data]
  );

  const isTrafficInfoPage = useMemo(
    () =>
      pipe(
        asPath,
        findLink,
        chain(fromPredicate((l) => l.ref === 'traffic_information')),
        match(
          () => false,
          () => true
        )
      ),
    [asPath, findLink]
  );

  const link = pipe(
    findLinkByCodeRef('traffic_information'),
    map((l) => `/${l.locale}${l.path}`),
    toUndefined
  );

  if (isTrafficInfoPage) {
    return null;
  }

  return (
    <aside className={cx(bgColor, 'border-b px-4 md:px-16')}>
      <Box className="relative flex justify-center py-3">
        {isStatusLoading ? (
          <>
            <Box className="h-[20px] w-[20px] animate-pulse rounded-full bg-neutral-400 md:h-[32px] md:w-[32px]" />
            <Box className="h-[20px] w-[98px] animate-pulse bg-neutral-400 md:h-[26px] md:w-[128px]" />
          </>
        ) : null}
        {!isStatusLoading ? (
          <Link
            href={link || ''}
            aria-label={t('bridgeStatus.link')}
            className={cx(
              buttonColor,
              'flex items-center rounded-full px-4 py-2 text-xs font-medium sm:text-sm '
            )}
          >
            {icon}
            <span className="ml-2">{text}</span>
          </Link>
        ) : null}
      </Box>
    </aside>
  );
};
