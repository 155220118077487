import { Banner, Box, PageWrapper } from '@oresundsbron/bridge-ui';
import { FC, useMemo } from 'react';
import { RichText } from '../components/RichText';
import { useInformationBanner } from '../hooks/useInformationBanner';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { WithContext, SpecialAnnouncement } from 'schema-dts';

export const InformationBanner: FC = () => {
  const { data } = useInformationBanner();

  const jsonld: WithContext<SpecialAnnouncement>[] = useMemo(() => {
    let announcements: WithContext<SpecialAnnouncement>[] = [];

    data?.items.map(function (infoBanner) {
      announcements.push({
        '@context': 'https://schema.org',
        '@type': 'SpecialAnnouncement',
        datePosted: infoBanner.sys.publishedAt,
        name: infoBanner?.content?.json
          ? documentToPlainTextString(infoBanner.content.json)
          : '',
      });
    });
    return announcements;
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      {jsonld && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonld) }}
        />
      )}
      {data.items.map((infoBanner) => (
        <Box
          key={infoBanner.sys.id}
          as={Banner}
          color={infoBanner.color}
          className="text-center"
        >
          <RichText content={infoBanner.content} />
        </Box>
      ))}
    </>
  );
};
